.services-section {
    padding: 50px 50px 0;
    .service {
        display: flex;
        justify-content: space-between;
        padding: 50px;
        align-items: center;

        &:nth-child(odd) {
            background: linear-gradient(to right, rgba(0, 150, 136, 0.2) , rgba(0, 150, 136, 0.5));
            flex-direction: row-reverse;
          }

        .service-text {
            width: 60%;

            .heading {
                margin-bottom: 30px;
            }
            .text {
                margin-bottom: 20px;
                font-size: 20px;
            }
          }
          .service-image {
            width: 250px;
            height: 250px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 4px 50px;
            }
          }
    }
}

@media screen and (max-width: 900px) {
    .services-section {
        padding: 20px;

        .service {
            padding: 0;
            flex-direction: column;
            margin-top: 20px;

            .service-text, .service-image {
                width: 100%;

                .heading {
                    font-size: 25px;
                    margin-bottom: 10px;
                }
                .text {
                    font-size: 15px;
                }
            }
        }
    }
}