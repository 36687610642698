body {
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.heading {
  font-size: 30px;
  color: #005776;
  font-size: 30px;
  font-weight: bold;
}

