.home-section {
    .home-banner {
        width: 100%;
        height: 400px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }

        .quotes {
            position: absolute;
            bottom: 0;
            left: 50px;
            color: #fff;
        }
    }
    .about-intro {
        display: flex;
        justify-content: space-between;
        padding: 50px;

        .intro-text {
            width: 40%;

            .heading {
                margin-bottom: 30px;
            }
            .text {
                margin-bottom: 20px;
                font-size: 20px;
            }
            button {
                padding: 10px 20px;
                font-size: 18px;
                background: linear-gradient(to right, #009688, rgba(0, 150, 136, 0.5));
                border: none;
                border-radius: 4px;
                display: flex;
                align-items: center;
                font-family: "Josefin Sans", sans-serif;
                cursor: pointer;
                

                &:hover {
                    i {
                        margin-left: 10px;
                    }
                }

                i {
                    margin-left: 5px;
                    -webkit-transition: 0.2s;
                     transition: 0.2s;
                }
            }
          }
          .intro-image {
            width: 40%;

            img {
                width: 100%;
                height: 100%;
            }
          }
    }

    .services-section {
        .title-section {
            padding: 50px;
            font-size: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            .hr{
                width: 100px;
                height: 2px;
                background: #ccc;
                margin: 20px;
            }
        }

        .services-tiles{
            display: flex;
            padding: 0 50px 50px;
            flex-wrap: wrap;
             
            img {
                border-radius: 4px;
                margin: 15px;
            }
        
        }
    }
}

@media screen and (max-width: 900px) {
    .home-section {
        .home-banner {
            height: 250px;

            .quotes {
                left: 20px;
            }
        }

        .about-intro {
            padding: 20px;
            flex-direction: column;

            .heading {
                font-size: 25px;
            }

            .intro-text, .intro-image {
                width: 100%;

                .text {
                    font-size: 15px;
                }

                button {
                    margin: 20px auto;
                }
            }
        }

        .services-section {
            .title-section {
                padding: 20px 5px;
                font-size: 25px;

                .hr {
                    width: 50px;
                }
            }

            .services-tiles {
                div {
                    width: 100px;
                    height: 100px;
                    margin: 15px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}