.header-top {
    display: flex;
    padding: 10px 50px;
    justify-content: space-between;
    align-items: center;
    background: #009688;;

    a {
        color: black;
        text-decoration: none;
    }

    img {
        width: 16px;
        margin-right: 5px;
    }

    .right-section {
        display: flex;

        div {
            display: flex;
            align-items: center;

            &:first-child {
                margin-right: 20px;
            }
        }
    }
}
.header {
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    align-items: center;


    .links-section {
       display: flex; 
       align-items: center;

       .link {
        padding: 5px 20px;
        margin: 0 5px;
        border: 2px solid transparent;

        &:hover {
            border: 2px solid #005776;
            border-radius: 20px;
        }
            a {
                text-decoration: none;
                color: black;
            }
       }

       .contact-button {
        margin-left: 20px;
        padding: 10px 0;
        background: linear-gradient(to right, #009688 , rgba(0, 150, 136, 0.5));;
        border: none;
        border-radius: 4px;
        font-family: 'Josefin Sans', sans-serif;
    
            a {
                text-decoration: none;
                color: white;
                padding: 20px;
            }
       }
    }
}

@media screen and (max-width: 900px) {
    .header {
        padding: 0 20px;
    } 
    .offcanvas.offcanvas-start.show {
        max-width: 75%;
        background-color: black;

        .link {
            padding: 20px 20px;

            a {
                color: white;
                text-decoration: none;
            }
        }

        .contact-button {
            margin-left: 20px;
            padding: 10px 0;
            background: linear-gradient(to right, #009688 , rgba(0, 150, 136, 0.5));;
            border: none;
            border-radius: 4px;
            font-family: 'Josefin Sans', sans-serif;
        
                a {
                    text-decoration: none;
                    color: white;
                    padding: 20px;
                }
           }
           
    }
  }