.contact-section {
    padding: 50px 0;
    position: relative;
    width: 60%;
    margin: auto;

    .contact-form {
        width: 80%;
        background: #eee;
        border-radius: 4px;

        .heading {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            padding: 15px;
        }

        input, textarea {
            margin: 10px 0;
            height: 45px;
            width: 400px;
            border-radius: 4px;
            border-color: #009688;
            font-size: 20px;
            padding: 0 15px;
            box-sizing: border-box;
            border: 3px solid #ccc;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            outline: none;
            margin-left: 25px;
            font-family: 'Josefin Sans', sans-serif;

            &:focus {
                border: 3px solid #555;
              }
        }

        button {
            margin: 25px;
            height: 45px;
            width: 400px;
            background: #009688;
            border: none;
            border-radius: 4px;
            font-size: 20px;
            font-family: 'Josefin Sans', sans-serif;
        }
    }

    .address-section {
        background: #009688;
        border-radius: 4px;
        width: 40%;
        position: absolute;
        top: 115px;
        padding: 50px 25px;
        box-sizing: border-box;
        display: inline-block;
        right: 0;
        color: white;
        
        .heading {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 20px;
        }

        div {
            span {
                padding: 0 20px;
                img {
                    width: 24px
                }
            }
        }
}
    
}

@media screen and (max-width: 900px) {
    .contact-section {
        padding: 20px;
        width: 100%;

        .contact-form {
            width: 100%;
            margin-bottom: 20px;

            input, button {
                width: 300px;
            }
        }

        .address-section {
            width: 100%;
            position: initial;
        }
    }
}